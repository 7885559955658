<style lang="scss" scoped>
@import "assets/scss/default.scss";
</style>

<template>
  <Header :isPrinter="true"></Header>
  <div id="wrapper">
    <Navbar :navLinks="navLinks"></Navbar>
    <main>
      <slot />
    </main>
  </div>
</template>

<script setup>
const navLinks = [
  { url: "/printers", name: "Dashboard", icon: "isax isax-element-4" },
  { url: "/printers/productions", name: "Productions", icon: "isax isax-d-cube-scan" },
  { url: "/printers/transactions", name: "Transactions", icon: "isax isax-convert-card" },
  { url: "/printers/stocks", name: "Stocks", icon: "isax isax-box" }
];
</script>

<script>
import HeaderPrinters from "~/components/headerPrinters.vue";

export default {
  name: "PrintersLayout",
  components: { HeaderPrinters },
  mounted() {
    const { $events } = useNuxtApp();
    // Loader
    $events.on("loading", ([state, text, fullHeight]) => {
      this.loadingText = text || "loader.loading";
      this.loading = state;
      this.fullHeight = fullHeight;

      // If loading add overflow hidden
      if (state) document.body.classList.add("overflow-hidden");
      else document.body.classList.remove("overflow-hidden");
      // Move the scroll to the top
      window.scrollTo(0, 0);
    });
  }
};
</script>
