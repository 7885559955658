<style lang="scss">
@import "assets/scss/components/header.scss";
</style>

<template>
  <header>
    <div class="left">
      <img alt="Logo de la marque" class="h-full" src="/images/logo.png">
    </div>
    <div class="right">
    </div>
  </header>
</template>

<script setup></script>

<script>
export default {
  name: "HeaderPrinters"
};
</script>
